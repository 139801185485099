import React from "react";
import {graphql} from "gatsby";
import Markdown from 'markdown-to-jsx';

import Layout from "../components/layouts/Layout";
import SectionLayoutGrid from "../components/layouts/SectionLayoutGrid";

import Col from "../components/styled/grid/Col";

const PrivacyPolicy = ({data}) => {

    const {lang, content, SEO} = data.privacy;

    return (
        <Layout lang={lang} seo={SEO}>
            <SectionLayoutGrid customBackground="middle">
                <Col className="markdown">
                    <Markdown children={content} />
                </Col>
                <Col>
                    <script id="CookieDeclaration" src="https://consent.cookiebot.com/d83cd00c-5e23-4df3-a9f5-f36e8b440faf/cd.js" type="text/javascript" async/>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSinglePrivacyPolicy($locale: String) {
         privacy: strapiPrivacyPolicies(lang: { eq: $locale }) {
            lang
            content
            SEO {
                title
                description
                keywords
                isIndexable
                preview {
                  url
                }
            }
         }
   }
 `

export default PrivacyPolicy
